import React from 'react'
import '../css/main.css'
import Styles from './projects.module.css'
import Hikers from '../assets/Hikers.png'
import Logo from '../assets/Logo.png'
import Galco from '../assets/Galco-Page.png'
import AmberLogo from '../assets/Amber-Logo-1200.png'
import Portfolio from '../assets/portfolio.png'
import Prototype from '../assets/prototype.png'

const Projects = () => {
        return (
            <div className={Styles.container}>
                <h1>Recent Work.</h1>
                <p>I’ve designed websites, applications, and prototypes while studying at Full Sail University, along with some freelance graphic design work. </p>
                <p>Here’s a sample of some of my favorite projects!</p>
                <div className={Styles.projContainer}>
                    <div className={Styles.left}>
                        <div className={Styles.project}>
                            <a href="https://www.galco.com/send-in-repair/howitworks.htm">
                            <h2>Galco</h2>
                            <img src={Galco} alt='Galco Page'></img></a>
                            <p>This is one of many pages that I have worked on updating on Galco’s website to match the updated styling on the new launch of the site. This page, along with most other pages I have updated, was a collaboration with the Senior Design Manager, updated with HTML and CSS – incorperating flexbox to easily make the page responsive. </p>
                        </div>
                        <div className={Styles.project}>
                            <a href="https://www.instagram.com/amberjunephoto/">
                            <h2>Amber June Photography</h2>
                            <img src={AmberLogo} alt='Photograpgy Logo'></img></a>
                            <p>This logo was created with Adobe Illustrator. I was hired on by an old friend wanting to make her hobby a career. We started planning a brand. This was the final product after creating a brand identity, sketching, drafts and edits. </p>
                        </div>
                       <div className={Styles.project}>
                            <h2>Design Prototype</h2>
                            <a href="https://xd.adobe.com/view/a734693d-ed6b-4f8c-a6c0-4d3fca463e53-f267/?fullscreen"><img src={Prototype} alt='Design Prototype'></img></a>
                            <p>This project was created using Adobe XD and was the final step in creating a prototype for a web application. During this process, I created low and high-fidelity wireframes, a design system, conducted surveys, and user testing. Finally with all of the steps and feedback in place I was able to update, change, and create a final prototype.  </p>
                        </div>
                    </div>
                    <div className={Styles.right}>
                        <div className={Styles.project}>
                            <h2>Hiker's Guide</h2>
                            <a href="https://veronicaweaver.github.io"><img src={Hikers} alt='Hiker`s Website'></img></a>
                            <p>This website was created with HTML5, CSS3, and JavaScript to bring a website to life, based on images given to me by my professor. The goal was to match the images as close as possible with only HEX codes and the images provided, along with making the site’s design suitable for all screens. </p>
                        </div>
                        <div className={Styles.project}>
                            <a href="https://veronicaweaver.github.io"></a>
                            <h2>Portfolio</h2>
                            <img src={Portfolio} alt='Portfolio'></img>
                            <p>The site you’re on right now was created with React. I wanted to create a single-page application that responsively handles devices of all sizes. I planned this site with wireframes, a design system, and a prototype that was user tested to get feedback that I implemented while working on the site as well as incorporated SEO.</p>
                        </div>
                         <div className={Styles.project}>
                            <h2>Grace Upon Grace</h2>
                            <a href="https://www.instagram.com/graceupongrace116_/"><img src={Logo} alt='Grage Upon Grace Logo'></img></a>
                            <p>This logo was created using Adobe Illustrator. I was hired on by a local small business to get them started on branding for packaging, social media, business cards, etc. This was the final approved design after discussing what the business meant to them, sketches, rough drafts, and edits. </p>
                        </div>
                    </div>
                </div>
            </div>
        )
}
export default Projects
