import React from 'react'
import '../css/main.css'
import Styles from './footer.module.css'
import Detroit from '../assets/Detroit-Skyline.jpg'

const Footer = () => {
        return (
            <div className={Styles.container}>
                <div className={Styles.city}>
                    <img src={Detroit} alt='Detroit City' className={Styles.img}></img>
                </div>
                <ul className={Styles.list}>
                    <li>Veronica Weaver</li>
                    <br/>
                    <li>2022</li>
                </ul>
            </div>
        )
}
export default Footer
