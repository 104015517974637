import React from 'react'
import '../css/main.css'
import Styles from './contact.module.css'
import Instagram from '../assets/instagram.png'
import Facebook from '../assets/facebook.png'
import LinkedIn from '../assets/linkedin.png'

const Contact = () => {
        return (
            <div id='contact' className={Styles.container}>
                <div className={Styles.social}>
                    <h1>Where to Find me.</h1>
                    <ul>
                        <li>
                            <img src={Instagram} className={Styles.icons} alt='Instagram Logo'></img>
                            <a href='https://www.instagram.com/weavzdesign/'>Instagram</a>
                        </li>
                        <li>
                            <img src={Facebook} className={Styles.icons} alt='Facebook Logo'></img>
                            <a href='https://www.facebook.com/veronica.weaver.7/'>Facebook</a>
                        </li>
                        <li>
                            <img src={LinkedIn} className={Styles.icons} alt='LinkedIn Logo'></img>
                            <a href='https://www.linkedin.com/in/veronica-weaver-00583918b/'>LinkedIn</a>
                        </li>
                    </ul>
                </div>
                <div className={Styles.why}>
                    <h1>Let's Work Together!</h1>
                    <p>My goal is to combine communication and creativity to bring ideas to life!</p>
                    <p>I believe that a website should show off what makes a business unique. Websites allow new eyes to see a brand’s vision from anywhere in the world, and I will go above and beyond to create an idea into a reality!
I would love to hear about any new projects and ideas! </p>
                       <p> Drop me a line at 
                        <a href="mailto:veronica0weaver@gmail.com?subject=Project Inquiry" className={Styles.email}> veronica0weaver@gmail.com.</a></p>
                </div>
            </div>
        )
}
export default Contact