import React from 'react'
import '../css/main.css'
import Styles from './about.module.css'
import Profile from '../assets/profile.jpg'

const About = () => {
        return (
            <div className={Styles.container}>
                <div className={Styles.bioContainer}>
                <div className={Styles.bio}>
                    <h1>Hey!
                    <br/> I'm Veronica Weaver.</h1>
                    <p>I’m a Web Designer living in the Metro-Detroit area. For as long as I can remember, I’ve always loved art. As a child, I was drawn to colors and textures, so I began to teach myself different design principles. In high school, I found my passion for Web Design and creating my own custom web pages. I followed this dream and graduated with honors and as valedictorian from Full Sail University with a B.Sc in Web Design and Development in 2021. 
                    <br/>
                    <br/>
                    While studying I had the privilege of learning and utilizing a wide range of technologies, growing my passion and skills even further. 
                    </p>
                <div className={Styles.lists}>
                <div className={Styles.left}>
                    <h4>Technical Skills</h4>
                    <ul>
                        <li>HTML5</li>
                        <li>CSS3</li>
                        <li>JavaScript</li>
                        <li>XHTML</li>
                        <li>React.js</li>
                        <li>Bootstrap</li>
                    </ul>
                </div>
                <div className={Styles.right}>
                    <h4> Software</h4>
                    <ul>
                        <li>Visual Studio</li>
                        <li>Git/GitHub</li>
                        <li>Adobe XD</li>
                        <li>InDesign</li>
                        <li>Photoshop</li>
                        <li>Illustrator</li>
                    </ul>
                </div>
                </div>
                </div>
                </div>
                <div className={Styles.imgContainer}>
                    <img className={Styles.img} src={Profile} alt='Veronica Weaver Profile, Blue hair'></img>
                </div>
            </div>
        )
}
export default About