import React from 'react'
import '../css/main.css'
import Styles from './header.module.css'

const Header = () => {
        return (
            <div className={Styles.container}>
                <div className={Styles.stars}></div>
                <ul className={Styles.ul}>
                    <li>Veronica Weaver</li>
                    <li><a href='#contact'>Get in Touch</a></li>
                </ul>
                <h1>Let's Create Something Together!</h1>
                <h2>Web & Graphic Designer</h2>
                <div className={Styles.btnContainer}>
                    <button className={Styles.btn}><a href="mailto:veronica0weaver@gmail.com?subject=Project Inquiry">Email Me</a></button>
                </div>
            </div>
        )
}
export default Header